<template>
    <div class="wit-checkbox">
        <b-form-checkbox-group>
            <label class="switch switch-label switch-pill switch-primary switch-show-password" data-children-count="1">
                <input class="switch-input" type="checkbox" v-model="internalValue" :disabled="disabled" />
                <span
                    class="switch-slider"
                    :data-checked="checked"
                    :data-unchecked="unchecked"
                    :data-state="`${internalValue}`"
                    :id="id"
                ></span>
            </label>
        </b-form-checkbox-group>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        id: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        checked: {
            type: String,
            default: () => 'Off',
        },
        unchecked: {
            type: String,
            default: () => 'On',
        },
    },
    data() {
        return {
            internalValue: null,
        }
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
        internalValue() {
            if (this.value !== this.internalValue) {
                this.emitInput()
            }
        },
    },
    created() {
        this.internalValue = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style>
.wit-checkbox {
    margin-top: 5px;
    margin-bottom: -5px;
}
</style>
